import React, {useEffect, useState} from 'react';
import {Route, Switch} from 'react-router-dom';
import Routing, {ROUTE_PATH} from "./routing";
import AppLayout from "@amzn/meridian/app-layout";
import Column from "@amzn/meridian/column";
import AppContextProvider from "./context/app-context";
import withDevSupport from "./dev-support";
import {IntlProvider} from "react-intl";
import LocaleManagerInstance from "./locale/locale-manager";
import {FeatureManager, MeshClient, PlayerHelper, DataHandler, NativeMeshInteractor} from "@amzn/dolphin-web-framework";
import AppDataStore, {APP_DATA_KEYS} from "./app-data";
import {Loader, TrainingBlockerView} from "@amzn/dolphin-uiweb-framework";
import PlayerContextProvider from "./player/context/PlayerContext";
import {PlayerConstants} from "./player/PlayerConstant";
import {getRegion, getEnv} from "./bridge/utils"
import STRINGS from "./locale/strings";
import {Modules} from "./constants/Modules";

const AppTopNavigation = React.lazy(() => import('./components/app-top-navigation'));

export default function App() {
    const locale = LocaleManagerInstance.getLocale();
    const messages = LocaleManagerInstance.getMessages();
    const [loadFeatures, setLoadFeatures] = useState(true);
    const [trainingBlocker, setTrainingBlocker] = useState(false);

    useEffect( () => {
        async function initialize() {
            await MeshClient.connectWebSocket();

            let redirectData = DataHandler.getRedirectData();
            const isRedirectFromStage = redirectData && (redirectData.redirectFromModule === Modules.STAGE);

            if (AppDataStore.getFeatures()) {
                DataHandler.setUserFeatures(AppDataStore.getFeatures());
                DataHandler.setModuleVersions(AppDataStore.getModuleVersions());
                setLoadFeatures(false);
            } else {
                await FeatureManager.getFeatures(AppDataStore.get(APP_DATA_KEYS.APP_ENV),
                    AppDataStore.get(APP_DATA_KEYS.APP_REGION)).then(() => {
                    setLoadFeatures(false);
                });
            }

            if (!isRedirectFromStage) {
                PlayerHelper.init(PlayerConstants.PROCESS_PATH, PlayerConstants.VERSION, getEnv(), getRegion())
                    .then(() => {
                        PlayerHelper.sendMessage(new window.DolphinPlayer.Pick.ProcessSelectedEvent(),
                            new window.DolphinPlayer.Pick.TaskSelectState());
                    })
            } else {
                PlayerHelper.init(PlayerConstants.PROCESS_PATH, PlayerConstants.VERSION, getEnv(), getRegion())
                    .then(() => {
                        PlayerHelper.sendMessage(new window.DolphinPlayer.Pick.PickCompleteOptionSelectedEvent(
                            window.DolphinPlayer.Pick.PickCompleteOption.PICK_ANOTHER_ROUTE),
                            new window.DolphinPlayer.Pick.TaskSelectState());
                    })
            }

            setTrainingBlocker(false);
        }
        initialize();
        return () => {
            MeshClient.closeWebSocketConnection();
            PlayerHelper.terminate();
        };
    },[]);

    const renderTrainingBlockerView = () => {
        return <TrainingBlockerView
            title={STRINGS.TRAINING_BLOCKER_TITLE}
            description={STRINGS.TRAINING_BLOCKER_DESC}
            buttonText={STRINGS.GOT_IT}
            buttonOnClick={() => NativeMeshInteractor.exitModule()}/>
    }

    return <IntlProvider locale={locale} messages={messages}>
        <PlayerContextProvider>
        <AppContextProvider>
            {loadFeatures ? <Loader/> : null}
            <AppLayout>
                {withDevSupport(
                    trainingBlocker
                        ? renderTrainingBlockerView()
                        : <Column id={"container"} width="100%" height="100%" spacing="none">
                            <React.Suspense fallback={null}>
                                {/* if route='/' exact true is null and otherwise we return the top nav*/}
                                <Switch>
                                    <Route exact={true} path={ROUTE_PATH.INDEX} component={() => null}/>
                                    <Route component={AppTopNavigation}/>
                                </Switch>
                            </React.Suspense>
                            <Column className="container" height="100%" heights="fill">
                                <Routing/>
                            </Column>
                        </Column>
                )}
            </AppLayout>
        </AppContextProvider>
        </PlayerContextProvider>
    </IntlProvider>;
}
