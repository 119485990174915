import {Logger, PlayerHelper} from "@amzn/dolphin-web-framework";
import ProgramType from "../../models/enums/program-type";
import {ApplicationActions, Operations} from "../action/ApplicationActions";
import STRINGS from "../../locale/strings";
import {BagColor, ContainerType, HelpTypes, ROUTE_PATH_FOR_PICKING} from "../PlayerConstant";
import ErrorCodes from "../../network/error-codes";
import {WeightCategory} from "../../models/enums/item-enums";
import {ROUTE_PATH} from "../../routing";
import AppDataStore from "../../app-data";
import {getItemToPick} from "../../components/execute-pick/do-picking-utils";



export const PlayerReducer = (state, action) => {
    try {
        if (state.scope === ProgramType.AMZL) {
            if (window && window.DolphinPlayer) {
                let event, nextState;
                switch (action.type) {
                    case ApplicationActions.DIRECTED_PICK_ENTRY:{
                        if (state.pickMorePicklist) {
                            event = new window.DolphinPlayer.Pick.PickCompleteOptionSelectedEvent(window.DolphinPlayer.Pick.PickCompleteOption.PICK_ANOTHER_ROUTE);
                            nextState = new window.DolphinPlayer.Pick.TaskSelectState();
                        } else {
                            event = new window.DolphinPlayer.Pick.ProcessSelectedEvent();
                            nextState = new window.DolphinPlayer.Pick.TaskSelectState();
                        }

                        PlayerHelper.sendMessage(event, nextState);
                        return {
                            ...state,
                            helpType: HelpTypes.DEFAULT
                        };
                    }
                    case ApplicationActions.TASK_SELECTION: {
                        if (action.data.operation === STRINGS.get_pick_list) {
                            event = new window.DolphinPlayer.Pick.TaskSelectedEvent(window.DolphinPlayer.Pick.PickTask.GET_PICK_LIST);
                            nextState = new window.DolphinPlayer.Pick.RouteDetailsState();

                        } else if (action.data.operation === STRINGS.enter_route_code) {
                            event = new window.DolphinPlayer.Pick.TaskSelectedEvent(window.DolphinPlayer.Pick.PickTask.ENTER_ROUTE_CODE);
                            nextState = new window.DolphinPlayer.Pick.RouteCodeEntryState(false);

                        } else if (action.data.operation === STRINGS.select_node) {
                            event = new window.DolphinPlayer.Pick.TaskSelectedEvent(window.DolphinPlayer.Pick.PickTask.GET_PICK_LIST);
                            nextState = new window.DolphinPlayer.Pick.NodeSelectState();

                        } else if (action.data.operation === ErrorCodes.NO_ASSIGNABLE_PICKLIST) {
                            event = new window.DolphinPlayer.Pick.TaskSelectedEvent(window.DolphinPlayer.Pick.PickTask.GET_PICK_LIST);
                            nextState = new window.DolphinPlayer.Pick.NoRouteAvailableState();

                        } else if (action.data.operation === ErrorCodes.PICKLIST_UNASSIGNED || action.data.operation === ErrorCodes.PICKLIST_NOT_IN_PROGRESS) {
                            event = new window.DolphinPlayer.Pick.TaskSelectedEvent(window.DolphinPlayer.Pick.PickTask.GET_PICK_LIST);
                            nextState = new window.DolphinPlayer.Pick.PicklistNotAssignedState();

                        }
                        PlayerHelper.sendMessage(event, nextState);
                        return {
                            ...state,
                            helpType: HelpTypes.DEFAULT
                        };
                    }
                    case ApplicationActions.ENTER_ROUTE_CODE: {
                        if (action.data.operation === Operations.VALID_ROUTE_CODE_ENTERED) {
                            event = new window.DolphinPlayer.Pick.RouteCodeEnteredEvent(true);
                            nextState = new window.DolphinPlayer.Pick.RouteDetailsState();

                        } else if (action.data.operation === ErrorCodes.ASSOCIATE_ALREADY_ASSIGNED) {
                            event = new window.DolphinPlayer.Pick.RouteCodeEnteredEvent(false);
                            nextState = new window.DolphinPlayer.Pick.RouteCodeEntryState(true, window.DolphinPlayer.Pick.RouteCodeEntryErrorType.ROUTE_ALREADY_ASSIGNED);

                        } else if (action.data.operation === ErrorCodes.INVALID_PICKLIST_ID) {
                            event = new window.DolphinPlayer.Pick.RouteCodeEnteredEvent(false);
                            nextState = new window.DolphinPlayer.Pick.RouteCodeEntryState(true, window.DolphinPlayer.Pick.RouteCodeEntryErrorType.INVALID_ROUTE_CODE);

                        } else if (action.data.operation === ErrorCodes.PICKLIST_ALREADY_ASSIGNED) {
                            event = new window.DolphinPlayer.Pick.RouteCodeEnteredEvent(false);
                            nextState = new window.DolphinPlayer.Pick.RouteCodeEntryState(true, window.DolphinPlayer.Pick.RouteCodeEntryErrorType.ROUTE_ASSIGNED_TO);

                        } else if (action.data.operation === ErrorCodes.PICKLIST_COMPLETED) {
                            event = new window.DolphinPlayer.Pick.RouteCodeEnteredEvent(false);
                            nextState = new window.DolphinPlayer.Pick.RouteCodeEntryState(true, window.DolphinPlayer.Pick.RouteCodeEntryErrorType.ROUTE_ALREADY_PICKED);
                        } else if (action.data.operation === ErrorCodes.MANUAL_ASSIGN_LIMIT_EXCEEDED) {
                            event = new window.DolphinPlayer.Pick.RouteCodeEnteredEvent(false);
                            nextState = new window.DolphinPlayer.Pick.RouteCodeEntryState(true, window.DolphinPlayer.Pick.RouteCodeEntryErrorType.MANUAL_ASSIGN_LIMIT_EXCEEDED);
                        }
                        PlayerHelper.sendMessage(event, nextState);
                        return {
                            ...state,
                            helpType: HelpTypes.DEFAULT
                        };
                    }
                    case ApplicationActions.NEW_CART_SCAN: {
                        const workFlow = AppDataStore.getWorkflow();
                        const remainingBags = workFlow.totalBagCount - (workFlow.completedBagCount + workFlow.tempMissingBagCount);
                        let containerType = remainingBags !== 0 ? window.DolphinPlayer.Pick.CartScanContainerType.BAG :
                            window.DolphinPlayer.Pick.CartScanContainerType.PACKAGE;
                        if (action.data.operation === Operations.VALID_CART_SCAN) {
                            event = new window.DolphinPlayer.Pick.BarcodeScannedEvent(true);
                            if (remainingBags !== 0) {
                                // For valid new cart scan, next state is bag scan. using default bag color as gray
                                nextState = new window.DolphinPlayer.Pick.BagScanState(window.DolphinPlayer.Pick.BagWeightClass.GRAY, false);
                            } else {
                                nextState = new window.DolphinPlayer.Pick.PackageScanState(false);
                            }
                        } else if (action.data.operation === ErrorCodes.CONTAINER_DOES_NOT_EXIST || action.data.operation === STRINGS.wrong_cart_scan) {
                            event = new window.DolphinPlayer.Pick.BarcodeScannedEvent(false);
                            nextState = new window.DolphinPlayer.Pick.CartScanState(containerType,
                                true, window.DolphinPlayer.Pick.CartScanErrorType.INVALID_BARCODE);

                        } else if (action.data.operation === ErrorCodes.CART_ALREADY_IN_USE) {
                            event = new window.DolphinPlayer.Pick.BarcodeScannedEvent(false);
                            nextState = new window.DolphinPlayer.Pick.CartScanState(containerType,
                                true, window.DolphinPlayer.Pick.CartScanErrorType.UNKNOWN);
                        } else if (action.data.operation === ErrorCodes.CART_NOT_EMPTY) {
                            event = new window.DolphinPlayer.Pick.BarcodeScannedEvent(false);
                            nextState = new window.DolphinPlayer.Pick.EmptyCartConfirmState();
                        }
                        PlayerHelper.sendMessage(event, nextState);
                        return {
                            ...state,
                            helpType: HelpTypes.CART_SCAN
                        };
                    }
                    case ApplicationActions.EMPTY_CART_CONFIRMATION: {
                        if (action.data.operation === Operations.ACTION_ACKNOWLEDGE) {
                            event = new window.DolphinPlayer.Pick.ActionAcknowledgedEvent();
                            const workFlow = AppDataStore.getWorkflow();
                            const remainingBags = workFlow.totalBagCount - (workFlow.completedBagCount + workFlow.tempMissingBagCount);
                            if (remainingBags !== 0) {
                                // For valid new cart scan, next state is bag scan. using default bag color as gray
                                nextState = new window.DolphinPlayer.Pick.BagScanState(window.DolphinPlayer.Pick.BagWeightClass.GRAY, false);
                            } else {
                                nextState = new window.DolphinPlayer.Pick.PackageScanState(false);
                            }
                        } else if (action.data.operation === Operations.ACTION_DECLINE) {
                            event = new window.DolphinPlayer.Pick.ActionDeclinedEvent();
                            nextState = new window.DolphinPlayer.Pick.CartScanState(window.DolphinPlayer.Pick.CartScanContainerType.BAG, false);
                        }
                        PlayerHelper.sendMessage(event, nextState);
                        return state;
                    }
                    case ApplicationActions.CART_SCAN: {
                        let containerType;
                        if (action.data.type === ContainerType.PACKAGE) {
                            containerType = window.DolphinPlayer.Pick.CartScanContainerType.PACKAGE;
                        } else {
                            containerType = window.DolphinPlayer.Pick.CartScanContainerType.BAG;
                        }
                        if (action.data.operation === Operations.VALID_CART_SCAN) {
                            let nextItem = getItemToPick(action.data.picklist.itemList);
                            event = new window.DolphinPlayer.Pick.BarcodeScannedEvent(true);
                            if (!nextItem) {
                                nextState = new window.DolphinPlayer.Pick.LocationScanState(false);
                            } else {
                                if (action.data.type === ContainerType.BAG) {
                                    let bagColor = window.DolphinPlayer.Pick.BagWeightClass.GRAY;
                                    if (action.data.bagWeight && action.data.bagWeight.category === WeightCategory.HEAVY) {
                                        bagColor = window.DolphinPlayer.Pick.BagWeightClass.YELLOW;
                                    }
                                    nextState = new window.DolphinPlayer.Pick.BagScanState(bagColor, false);
                                } else if (action.data.type === ContainerType.PACKAGE) {
                                    nextState = new window.DolphinPlayer.Pick.PackageScanState(false);
                                }
                            }
                        } else if (action.data.operation === ErrorCodes.CONTAINER_DOES_NOT_EXIST) {
                            event = new window.DolphinPlayer.Pick.BarcodeScannedEvent(false);
                            nextState = new window.DolphinPlayer.Pick.CartScanState(containerType,
                                true, window.DolphinPlayer.Pick.CartScanErrorType.INVALID_BARCODE);

                        } else if (action.data.operation === ErrorCodes.CART_ALREADY_IN_USE) {
                            event = new window.DolphinPlayer.Pick.BarcodeScannedEvent(false);
                            nextState = new window.DolphinPlayer.Pick.CartScanState(containerType,
                                true, window.DolphinPlayer.Pick.CartScanErrorType.UNKNOWN);

                        } else if (action.data.operation === Operations.INCORRECT_CART) {
                            event = new window.DolphinPlayer.Pick.BarcodeScannedEvent(false);
                            nextState = new window.DolphinPlayer.Pick.PackageScanState(true,
                                window.DolphinPlayer.Pick.PackageScanErrorType.INVALID_CART);
                        }
                        PlayerHelper.sendMessage(event, nextState);
                        return {
                            ...state,
                            helpType: HelpTypes.CART_SCAN
                        };
                    }
                    case ApplicationActions.ITEM_SCAN: {
                        if (action.data.type === ContainerType.BAG) {
                            let bagColorState = BagColor.GRAY;
                            let bagColor = window.DolphinPlayer.Pick.BagWeightClass.GRAY;
                            if (action.data.bagWeight && action.data.bagWeight.category === WeightCategory.HEAVY) {
                                bagColor = window.DolphinPlayer.Pick.BagWeightClass.YELLOW;
                                bagColorState = BagColor.YELLOW;
                            }
                            if (action.data.operation === Operations.VALID_ITEM_SCAN) {
                                if (action.data.isMixedCart) {
                                    event = new window.DolphinPlayer.Pick.BarcodeScannedEvent(true);
                                    nextState = new window.DolphinPlayer.Pick.MixedCartNoticeState()
                                } else {
                                    event = new window.DolphinPlayer.Pick.BarcodeScannedEvent(true);
                                    nextState = new window.DolphinPlayer.Pick.CartScanState(window.DolphinPlayer.Pick.CartScanContainerType.BAG, false);
                                }
                            } else if (action.data.operation === Operations.INVALID_ITEM_SCAN) {
                                event = new window.DolphinPlayer.Pick.BarcodeScannedEvent(false);
                                nextState = new window.DolphinPlayer.Pick.BagScanState(bagColor, true,
                                    window.DolphinPlayer.Pick.BagScanErrorType.INVALID_SCAN);

                            } else if (action.data.operation === Operations.DIRECTED_ITEM_PICKED) {
                                event = new window.DolphinPlayer.Pick.BarcodeScannedEvent(false);
                                nextState = new window.DolphinPlayer.Pick.BagScanState(bagColor, true,
                                    window.DolphinPlayer.Pick.BagScanErrorType.BAG_ALREADY_PICKED);

                            } else if (action.data.operation === Operations.ITEM_SKIPPED_NOTIFICATION) {
                                event = new window.DolphinPlayer.Pick.BarcodeScannedEvent(false);
                                nextState = new window.DolphinPlayer.Pick.BagScanState(bagColor, true,
                                    window.DolphinPlayer.Pick.BagScanErrorType.BAG_SKIPPED);

                            } else if (action.data.operation === Operations.MANUAL_SCAN) {
                                event = new window.DolphinPlayer.Pick.BarcodeScannedEvent(true);
                                nextState = new window.DolphinPlayer.Pick.BagScanState(bagColor, false);

                            } else if (action.data.operation === Operations.WRONG_BARCODE) {
                                event = new window.DolphinPlayer.Pick.BarcodeScannedEvent(false);
                                nextState = new window.DolphinPlayer.Pick.BagScanState(bagColor, true,
                                    window.DolphinPlayer.Pick.BagScanErrorType.WRONG_BARCODE);

                            } else if (action.data.operation === Operations.ITEM_REMOVED_FROM_PICKLIST) {
                                event = new window.DolphinPlayer.Pick.BarcodeScannedEvent(false);
                                nextState = new window.DolphinPlayer.Pick.PackageScanState(true,
                                    window.DolphinPlayer.Pick.PackageScanErrorType.BAG_REMOVED);
                            }
                            PlayerHelper.sendMessage(event, nextState);
                            return {
                                ...state,
                                helpType: HelpTypes.BAG_SCAN,
                                bagColor: bagColorState
                            };
                        } else if (action.data.type === ContainerType.PACKAGE) {
                            if (action.data.operation === Operations.VALID_ITEM_SCAN) {
                                event = new window.DolphinPlayer.Pick.BarcodeScannedEvent(true);
                                nextState = new window.DolphinPlayer.Pick.CartScanState(window.DolphinPlayer.Pick.CartScanContainerType.PACKAGE, false);

                            } else if (action.data.operation === Operations.INVALID_ITEM_SCAN) {
                                event = new window.DolphinPlayer.Pick.BarcodeScannedEvent(false);
                                nextState = new window.DolphinPlayer.Pick.PackageScanState(true,
                                    window.DolphinPlayer.Pick.PackageScanErrorType.INVALID_BARCODE);

                            } else if (action.data.operation === Operations.DIRECTED_ITEM_PICKED) {
                                event = new window.DolphinPlayer.Pick.BarcodeScannedEvent(false);
                                nextState = new window.DolphinPlayer.Pick.PackageScanState(true,
                                    window.DolphinPlayer.Pick.PackageScanErrorType.PACKAGE_ALREADY_PICKED);

                            } else if (action.data.operation === Operations.ITEM_SKIPPED_NOTIFICATION) {
                                event = new window.DolphinPlayer.Pick.BarcodeScannedEvent(false);
                                nextState = new window.DolphinPlayer.Pick.PackageScanState(true,
                                    window.DolphinPlayer.Pick.PackageScanErrorType.PACKAGE_SKIPPED);

                            } else if (action.data.operation === Operations.MOVED_TO_PSBIN) {
                                event = new window.DolphinPlayer.Pick.BarcodeScannedEvent(true);
                                nextState = new window.DolphinPlayer.Pick.PackageScanState(true,
                                    window.DolphinPlayer.Pick.PackageScanErrorType.PACKAGE_DAMAGED);

                            } else if (action.data.operation === Operations.INCORRECT_PSBIN) {
                                event = new window.DolphinPlayer.Pick.BarcodeScannedEvent(false);
                                nextState = new window.DolphinPlayer.Pick.PackageScanState(true,
                                    window.DolphinPlayer.Pick.PackageScanErrorType.INVALID_PSBIN);

                            } else if (action.data.operation === Operations.MANUAL_SCAN) {
                                event = new window.DolphinPlayer.Pick.BarcodeScannedEvent(true);
                                nextState = new window.DolphinPlayer.Pick.PackageScanState(false);

                            } else if (action.data.operation === Operations.VALID_DAMAGED_ITEM_SCAN) {
                                event = new window.DolphinPlayer.Pick.BarcodeScannedEvent(true);
                                nextState = new window.DolphinPlayer.Pick.ProblemSolveBinScanState();

                            } else if (action.data.operation === Operations.WRONG_BARCODE) {
                                event = new window.DolphinPlayer.Pick.BarcodeScannedEvent(false);
                                nextState = new window.DolphinPlayer.Pick.PackageScanState(true,
                                    window.DolphinPlayer.Pick.PackageScanErrorType.WRONG_BARCODE);

                            } else if (action.data.operation === Operations.ITEM_REMOVED_FROM_PICKLIST) {
                                event = new window.DolphinPlayer.Pick.BarcodeScannedEvent(false);
                                nextState = new window.DolphinPlayer.Pick.PackageScanState(true,
                                    window.DolphinPlayer.Pick.PackageScanErrorType.PACKAGE_REMOVED);
                            }
                            PlayerHelper.sendMessage(event, nextState);
                            return {
                                ...state,
                                helpType: HelpTypes.PACKAGE_SCAN
                            };
                        }
                        return state;
                    }
                    case ApplicationActions.CART_FULL: {
                        if (state.helpType === HelpTypes.PACKAGE_SCAN) {
                            event = new window.DolphinPlayer.Pick.PackageScanOptionSelectedEvent(window.DolphinPlayer.Pick.PackageScanOption.CART_FULL);
                            //using location scan state with has error false  after selecting cart full option
                            nextState = new window.DolphinPlayer.Pick.LocationScanState(false);
                        } else if (state.helpType === HelpTypes.BAG_SCAN) {
                            event = new window.DolphinPlayer.Pick.BagScanOptionSelectedEvent(window.DolphinPlayer.Pick.BagScanOption.CART_FULL);
                            //using location scan state with has error false  after selecting cart full option
                            nextState = new window.DolphinPlayer.Pick.LocationScanState(false);
                        }
                        PlayerHelper.sendMessage(event, nextState);
                        return state;
                    }

                    case ApplicationActions.HELP_OPTION_SELECTED: {
                        if (action.data.operation === Operations.ITEM_MISSING && action.data.type === ContainerType.PACKAGE) {
                            event = new window.DolphinPlayer.Pick.HelpCenterOptionSelectedEvent(window.DolphinPlayer.Pick.HelpCenterOption.PACKAGE_MISSING);
                            nextState = new window.DolphinPlayer.Pick.PackageMissingConfirmState();

                        } else if (action.data.operation === Operations.ITEM_MISSING && action.data.type === ContainerType.BAG) {
                            event = new window.DolphinPlayer.Pick.HelpCenterOptionSelectedEvent(window.DolphinPlayer.Pick.HelpCenterOption.BAG_MISSING);
                            nextState = new window.DolphinPlayer.Pick.BagMissingConfirmState();

                        } else if (action.data.operation === Operations.ITEM_DAMAGED && !action.data.type) {
                            event = new window.DolphinPlayer.Pick.HelpCenterOptionSelectedEvent(window.DolphinPlayer.Pick.HelpCenterOption.ITEM_DAMAGED);
                            nextState = new window.DolphinPlayer.Pick.DamagedItemScanState();

                        } else if (action.data.operation === Operations.MANUAL_SCAN && action.data.type === ContainerType.PACKAGE) {
                            event = new window.DolphinPlayer.Pick.HelpCenterOptionSelectedEvent(window.DolphinPlayer.Pick.HelpCenterOption.MANUAL_PACKAGE_SCAN);
                            nextState = new window.DolphinPlayer.Pick.ManualPackageScanState();

                        } else if (action.data.operation === Operations.MANUAL_SCAN && action.data.type === ContainerType.BAG) {
                            event = new window.DolphinPlayer.Pick.HelpCenterOptionSelectedEvent(window.DolphinPlayer.Pick.HelpCenterOption.MANUAL_BAG_SCAN);
                            nextState = new window.DolphinPlayer.Pick.ManualBagScanState();

                        } else if (action.data.operation === Operations.CART_NOT_FOUND) {
                            event = new window.DolphinPlayer.Pick.HelpCenterOptionSelectedEvent(window.DolphinPlayer.Pick.HelpCenterOption.CART_MISSING);
                            //using cart scan state with default container type BAG as next state after selecting cart not found option
                            nextState = new window.DolphinPlayer.Pick.CartScanState(window.DolphinPlayer.Pick.CartScanContainerType.BAG, false);
                        }
                        PlayerHelper.sendMessage(event, nextState);
                        return state;
                    }
                    case ApplicationActions.PICKING_COMPLETE: {
                        if (action.data.operation === Operations.PICK_MORE) {
                            event = new window.DolphinPlayer.Pick.PickCompleteOptionSelectedEvent(window.DolphinPlayer.Pick.PickCompleteOption.PICK_ANOTHER_ROUTE);
                            if (action.data.type) {
                                nextState = new window.DolphinPlayer.Pick.RouteDetailsState();
                            } else {
                                return {
                                    ...state,
                                    pickMorePicklist: true
                                };
                            }
                        } else if (action.data.operation === Operations.END_PICKING) {
                            event = new window.DolphinPlayer.Pick.PickCompleteOptionSelectedEvent(window.DolphinPlayer.Pick.PickCompleteOption.END_PICKING);
                            nextState = new window.DolphinPlayer.Pick.TaskSelectState();
                        }
                        PlayerHelper.sendMessage(event, nextState);
                        return state;
                    }
                    case ApplicationActions.STAGE_REDIRECTED_PICKING_COMPLETE: {
                        event = new window.DolphinPlayer.Pick.BarcodeScannedEvent(true);
                        nextState = new window.DolphinPlayer.Pick.PickCompleteState();
                        PlayerHelper.sendMessage(event, nextState);
                        return state;
                    }
                    case ApplicationActions.STAGING_LOCATION_SCANNED: {
                        if (action.data.operation === Operations.VALID_LOCATION_SCAN) {
                            event = new window.DolphinPlayer.Pick.BarcodeScannedEvent(true);
                            if (action.data.type) {
                                nextState = new window.DolphinPlayer.Pick.CartScanState(window.DolphinPlayer.Pick.CartScanContainerType.BAG, false);
                            } else {
                                nextState = new window.DolphinPlayer.Pick.PickCompleteState();
                            }
                        } else if (action.data.operation === Operations.INVALID_LOCATION_SCAN) {
                            event = new window.DolphinPlayer.Pick.BarcodeScannedEvent(false);
                            nextState = new window.DolphinPlayer.Pick.LocationScanState(true);
                        }
                        PlayerHelper.sendMessage(event, nextState);
                        return state;
                    }
                    case ApplicationActions.OPEN_HELP_OPTION: {
                        if (state.helpType === HelpTypes.BAG_SCAN) {
                            event = new window.DolphinPlayer.Pick.HelpCenterOpenedEvent();
                            nextState = new window.DolphinPlayer.Pick.HelpCenterState(window.DolphinPlayer.Pick.HelpCenterContext.BAG_SCAN);
                        } else if (state.helpType === HelpTypes.PACKAGE_SCAN) {
                            event = new window.DolphinPlayer.Pick.HelpCenterOpenedEvent();
                            nextState = new window.DolphinPlayer.Pick.HelpCenterState(window.DolphinPlayer.Pick.HelpCenterContext.PACKAGE_SCAN);
                        } else if (state.helpType === HelpTypes.CART_SCAN) {
                            event = new window.DolphinPlayer.Pick.HelpCenterOpenedEvent();
                            nextState = new window.DolphinPlayer.Pick.HelpCenterState(window.DolphinPlayer.Pick.HelpCenterContext.CART_SCAN);
                        } else if (state.helpType === HelpTypes.DEFAULT) {
                            event = new window.DolphinPlayer.Pick.HelpCenterOpenedEvent();
                            nextState = new window.DolphinPlayer.Pick.HelpCenterState(window.DolphinPlayer.Pick.HelpCenterContext.NOT_IMPLEMENTED);
                        }
                        PlayerHelper.sendMessage(event, nextState);
                        return state;
                    }
                    case ApplicationActions.UPDATE_STATE: {
                        let item = state.item;
                        let helpType = action.data.type;
                        if (helpType === HelpTypes.PACKAGE_SCAN) {
                            item = ContainerType.PACKAGE;
                        } else if (helpType === HelpTypes.BAG_SCAN) {
                            item = ContainerType.BAG;
                        }
                        return {
                            ...state,
                            helpType: helpType,
                            item: item
                        }
                    }
                    case ApplicationActions.ITEM_SUMMARY: {
                        if (action.data.type === ContainerType.BAG) {
                            event = new window.DolphinPlayer.Pick.SummaryTriggeredEvent();
                            nextState = new window.DolphinPlayer.Pick.BagSummaryState();
                        } else if (action.data.type === ContainerType.PACKAGE) {
                            event = new window.DolphinPlayer.Pick.SummaryTriggeredEvent();
                            nextState = new window.DolphinPlayer.Pick.PackageSummaryState();
                        }
                        PlayerHelper.sendMessage(event, nextState);
                        return {
                            ...state,
                            helpType: HelpTypes.DEFAULT
                        };
                    }
                    case ApplicationActions.ITEM_SUMMARY_CONTINUE_OPTION: {
                        if (action.data.type === ContainerType.BAG) {
                            event = new window.DolphinPlayer.Pick.ActionAcknowledgedEvent();
                            nextState = new window.DolphinPlayer.Pick.PackageScanState(false);
                        } else if (action.data.type === ContainerType.PACKAGE) {
                            event = new window.DolphinPlayer.Pick.ActionAcknowledgedEvent();
                            nextState = new window.DolphinPlayer.Pick.LocationScanState(false);
                        }
                        PlayerHelper.sendMessage(event, nextState);
                        return state;
                    }
                    case ApplicationActions.ON_BACK_SELECT: {
                        if (state.isBackSelect) {
                            event = new window.DolphinPlayer.Pick.BackActionSelectedEvent();
                            switch (action.data.operation) {
                                case ROUTE_PATH.HOME:
                                    nextState = new window.DolphinPlayer.Pick.TaskSelectState();
                                    break;
                                case ROUTE_PATH.INPUT_ROUTE:
                                    nextState = new window.DolphinPlayer.Pick.RouteCodeEntryState(false);
                                    break;
                                case ROUTE_PATH.ADD_CART_FOR_PICKING:
                                case ROUTE_PATH.WITH_DO_PICKING.ADD_CART:
                                case ROUTE_PATH.CART_CONFIRMATION:
                                    if (state.item === ContainerType.PACKAGE) {
                                        nextState = new window.DolphinPlayer.Pick.CartScanState(window.DolphinPlayer.Pick.CartScanContainerType.PACKAGE, false);
                                    } else {
                                        nextState = new window.DolphinPlayer.Pick.CartScanState(window.DolphinPlayer.Pick.CartScanContainerType.BAG, false);
                                    }
                                    break;
                                case ROUTE_PATH.PRE_PICK_CONFIRMATION:
                                    nextState = new window.DolphinPlayer.Pick.RouteDetailsState();
                                    break;
                                case ROUTE_PATH.WITH_DO_PICKING.STAGE_CART:
                                    nextState = new window.DolphinPlayer.Pick.LocationScanState(false);
                                    break;
                                case ROUTE_PATH.PICKING_COMPLETE:
                                    nextState = new window.DolphinPlayer.Pick.PickCompleteState();
                                    break;
                                default:
                                    if (action.data.operation.includes(ROUTE_PATH_FOR_PICKING.SCAN_CART_FOR_PICKING)) {
                                        if (state.item === ContainerType.PACKAGE) {
                                            nextState = new window.DolphinPlayer.Pick.CartScanState(window.DolphinPlayer.Pick.CartScanContainerType.PACKAGE, false);
                                        } else {
                                            nextState = new window.DolphinPlayer.Pick.CartScanState(window.DolphinPlayer.Pick.CartScanContainerType.BAG, false);
                                        }
                                    } else if (action.data.operation.includes(ROUTE_PATH_FOR_PICKING.WORKFLOW_TRANSITION)) {
                                        if (state.item === ContainerType.PACKAGE) {
                                            nextState = new window.DolphinPlayer.Pick.PackageSummaryState();
                                        } else {
                                            nextState = new window.DolphinPlayer.Pick.BagSummaryState();
                                        }
                                    } else if (action.data.operation === ROUTE_PATH.DO_PICKING || action.data.operation.includes(ROUTE_PATH_FOR_PICKING.PICK_ITEM)) {
                                        if (state.item === ContainerType.PACKAGE) {
                                            nextState = new window.DolphinPlayer.Pick.PackageScanState(false);
                                        } else if (state.item === ContainerType.BAG) {
                                            let bagColor = window.DolphinPlayer.Pick.BagWeightClass.GRAY;
                                            if (state.bagColor === BagColor.YELLOW) {
                                                bagColor = window.DolphinPlayer.Pick.BagWeightClass.YELLOW;
                                            }
                                            nextState = new window.DolphinPlayer.Pick.BagScanState(bagColor, false);
                                        }
                                    }
                                    break;
                            }
                            PlayerHelper.sendMessage(event, nextState);
                        }
                        return {
                            ...state,
                            isBackSelect: false
                        };
                    }
                    case ApplicationActions.ACKNOWLEDGE_AND_EXIT: {
                        event = new window.DolphinPlayer.Pick.ActionAcknowledgedEvent();
                        nextState = new window.DolphinPlayer.Pick.TaskSelectState();
                        PlayerHelper.sendMessage(event, nextState);
                        return state;
                    }
                    case ApplicationActions.MIXED_CART_NOTICE: {
                        if (action.data.operation === Operations.ACTION_ACKNOWLEDGE) {
                            event = new window.DolphinPlayer.Pick.ActionAcknowledgedEvent();
                            nextState = new window.DolphinPlayer.Pick.CartScanState(window.DolphinPlayer.Pick.CartScanContainerType.BAG, false);
                        }
                        PlayerHelper.sendMessage(event, nextState);
                        return state;
                    }
                    case ApplicationActions.ROUTE_CONFIRMATION: {
                        event = new window.DolphinPlayer.Pick.ActionAcknowledgedEvent();
                        nextState = new window.DolphinPlayer.Pick.CartScanState(window.DolphinPlayer.Pick.CartScanContainerType.BAG, false);
                        PlayerHelper.sendMessage(event, nextState);
                        return {
                            ...state,
                            helpType: HelpTypes.CART_SCAN
                        };
                    }
                    case ApplicationActions.UPDATE_BACK_SELECT_STATE: {
                        if (!action.data || (action.data && !action.data.value)) {
                            return {
                                ...state,
                                isBackSelect: true
                            };
                        }
                        return state;
                    }
                    case ApplicationActions.ON_BACK_DROP_OPEN:
                        event = new window.DolphinPlayer.Pick.BackActionSelectedEvent();
                        nextState = new window.DolphinPlayer.Pick.BackActionConfirmState();
                        PlayerHelper.sendMessage(event, nextState);
                        return state;
                    case ApplicationActions.ON_EXIT_MODULE:
                        event = new window.DolphinPlayer.Pick.BackActionSelectedEvent();
                        nextState = new window.DolphinPlayer.Pick.TaskSelectState();
                        PlayerHelper.sendMessage(event, nextState);
                        return state;
                    case ApplicationActions.SELECT_NODE:
                        event = new window.DolphinPlayer.Pick.ActionAcknowledgedEvent();
                        nextState = new window.DolphinPlayer.Pick.RouteDetailsState();
                        PlayerHelper.sendMessage(event, nextState);
                        return state;
                    default:
                        return state;
                }
            } else {
                Logger.log.info("Drona Player not initialised, message not sent");
                return {
                    ...state,
                    isInitialized: false,
                };
            }
        }
        return {
            ...state,
            isInitialized: false,
        };
    } catch(error) {
        Logger.log.info("Player app message exception", error);
    }

};
